import { GetInputModel, ResponseList } from './response.model';
export class LanguageObj {
    limit = 10;
    page = 0;
    results: Language[] = [];
    totalPages = 0;
    totalResults = 0;
}
export class Language {
    name = '';
    code = '';
    description = '';
    image = '';
    id = '';
    imageUrl = '';
}
export class CreateOrEditLanguage {
    name = '';
    code = '';
    description = '';
    image = '';
}
export class GetLanguageInput extends GetInputModel {
    name = '';
    code = '';
}
export class LanguageListResponse extends ResponseList {
    override results: Language[] = [];
}